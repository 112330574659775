function url($url) {
    var url = $url.split( '//' );
    if (url[0] === "http:" || url[0] === "https:") {
        var protocol = url[0] + "//";
        var host = url[1].split( '/' )[0];
        url = protocol + host;
        var path = $url.split(url)[1];
        return {
            protocol: protocol,
            host: host,
            path: path
        };
    }
}
export function loadScript(src){
    return new Promise(function (resolve, reject) {
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.src = src;
        scriptElement.onload = resolve;
        scriptElement.onerror = reject;
        document.head.appendChild(scriptElement);
    });
}

export async function loadReactScript(srcManifest){
    try {
        let datetime = "?t="+new Date().getTime();
        const response = await fetch(`${srcManifest}${datetime}`);
        const manifest = await response.json();
        const manifestScriptPath = url(response.url);
        const scriptPath = manifestScriptPath.path.replace(`/asset-manifest.json${datetime}`,"");

        const mainScript = manifest['files']['main.js'];

        const script = document.createElement('script');
        script.src = scriptPath+mainScript;
        script.async = true;
        script.onload = function (){
            return true;
        }
        document.body.appendChild(script);
    } catch (error) {
        console.error('Failed to load main script:', error);
        return false;
    }
}