export function itemlistSlideshow (query = document.querySelectorAll('.itemboxList')) {
    for (let slideshow of query) {
        var slideshowSwiper = slideshow.swiper;
        if(slideshowSwiper){
            slideshowSwiper.params.slidesPerView = 4;

            if(window.screen.width < 1250 && window.screen.width > 860) {
                slideshowSwiper.params.slidesPerView = 3;
                slideshowSwiper.params.spaceBetween = 20;
            }

            if(window.screen.width < 860 && window.screen.width > 540) {
                slideshowSwiper.params.slidesPerView = 2;
                slideshowSwiper.params.spaceBetween = 20;
            }

            if(window.screen.width < 540) {
                slideshowSwiper.params.slidesPerView = 1;
                slideshowSwiper.params.spaceBetween = 20;
            }
        }
    }
}