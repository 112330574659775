import {countUp} from "./countup";
import {manipulateSlideshow1} from "./manipulateSlideshow1";
import {testimonialsSlider} from "./testimonialsSlider";
import {shortScripts} from "./shortScripts";
import {manipulateSlideshow2} from "./manipulateSlideshow2";
import {shoppingSwitch} from "./shoppingSwitch";
import {loadConfigurator} from "./custom/loadConfigurator";
import {variantSelect} from "./variantSelect";
import {manipulateThumbs} from "./custom/manipulateThumbs";
import {itemlistSlideshow} from "./custom/itemlistSlideshow";
import {actionCardSlider} from "./custom/actionCardSlider";
import {video} from "./custom/video";
import {login} from "./custom/login";
import {trustedShops} from "./custom/trustedShops";
import {itemlistSlideshowAccount} from "./custom/itemlistSlideshowAccount";

window.addEventListener('load', function() {
    actionCardSlider();
    countUp();
    itemlistSlideshow();
    login();
    manipulateSlideshow1();
    manipulateSlideshow2();
    manipulateThumbs();
    shoppingSwitch();
    shortScripts();
    testimonialsSlider();
    trustedShops();
    loadConfigurator();
    variantSelect();
    video();
    itemlistSlideshowAccount();
});

window.addEventListener('resize', function() {
    actionCardSlider();
    manipulateSlideshow1();
    shortScripts();
});