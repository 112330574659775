export function trustedShops() {
    if(!sessionStorage.getItem('ratingAverage')) {
        getRatings();
    }
    ratingInput(sessionStorage.getItem('ratingAverage'), sessionStorage.getItem('ratingPercentage'), sessionStorage.getItem('ratingTotal'));
}

function getRatings() {
    fetch('/layout/frontend/lukasundlara/customphp/trustedshops.php', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Fehler bei der Anfrage: ' + response.status);
            }
            return response.json();
        })
        .then(data => {
            let ratingTotal = data.total;
            let rating = parseFloat(data.average.toFixed(2));
            let ratingPercentage = rating / 5.0 * 100;
            sessionStorage.setItem("ratingTotal", ratingTotal);
            sessionStorage.setItem("ratingAverage", rating);
            sessionStorage.setItem("ratingPercentage", ratingPercentage);
            ratingInput(rating, ratingPercentage, ratingTotal);
        })
        .catch(error => {
            console.error('Fehler:', error);
        });
}

function ratingInput(average, percentage, total) {
    let ratings = document.querySelectorAll('.rating');
    for (let ratingWraps of ratings) {
        let ratingStars = ratingWraps.querySelector('.rating__starsActive');
        let ratingVal = ratingWraps.querySelector('.ratingValue');
        let ratingTotal = ratingWraps.querySelector('.ratingTotal');
        if(ratingVal && ratingTotal) {
            ratingVal.textContent = average;
            ratingTotal.textContent = total;
        }
        if(ratingStars) {
            ratingStars.style.width = percentage + "%";
        }
    }
}