import Swiper, {Autoplay, Navigation, Pagination, Scrollbar, Thumbs} from '../../../node_modules/swiper';

export function manipulateThumbs () {

    let mainMediaSlider = document.querySelector('.js-itemcardImagesMain');
    let thumbsMediaSlider = document.querySelector('.js-itemcardImagesThumbs');
    if(mainMediaSlider && thumbsMediaSlider) {
        let swiperMediaSlider = mainMediaSlider.swiper;
        let swiperThumbsSlider = thumbsMediaSlider.swiper;

        swiperMediaSlider.destroy(false, true);
        swiperThumbsSlider.destroy(false, true);
        Swiper.use([Autoplay, Pagination, Navigation, Thumbs]);
        let thumbsSlider = new Swiper(thumbsMediaSlider, {
            autoplay: false,
            loop: false,
            slidesPerView: 4
        });

        let slideshowNew = new Swiper(".js-itemcardImagesMain", {
            autoplay: false,
            loop: true,
            items: 1,
            pagination: {
                el: '.js-itemcardImagesMain .swiper-pagination',
                clickable: true,
            },
            thumbs: {
                swiper: thumbsSlider
            },
            breakpoints: {
                1250: {
                    pagination: false
                }
            }
        });
    }
}