import Swiper, {Navigation} from '../../../node_modules/swiper';
import {Autoplay, Pagination} from "swiper";
export function actionCardSlider (actionCard = document.querySelector('.actionCards')) {
    if(actionCard) {

        if(window.screen.width < 1024) {
            actionCard.classList.add('swiper');
            let row = actionCard.querySelector('.row');
            let slides = actionCard.querySelectorAll('.row > div');
            for(let slide of slides) {
                slide.classList.add('swiper-slide');
            }
            if(!actionCard.querySelector('#actionCardBack, #actionCardPrev')) {
                let btnPrev = document.createElement('div');
                btnPrev.innerHTML = '<i class="icon icon-angle-left"></i>';
                btnPrev.setAttribute('id', 'actionCardPrev');
                actionCard.appendChild(btnPrev);
                let btnNext = document.createElement('div');
                btnNext.innerHTML = '<i class="icon icon-angle-right"></i>';
                btnNext.setAttribute('id', 'actionCardNext');
                actionCard.appendChild(btnNext);
            }

            let btnPrev = document.getElementById('actionCardPrev');
            let btnNext = document.getElementById('actionCardNext');

            row.classList.add('swiper-wrapper');
            Swiper.use([Navigation]);
            let actionCardSlider = new Swiper('.actionCards', {
                autoplay: false,
                slidesPerView: 1,
                navigation: {
                    nextEl: btnNext,
                    prevEl: btnPrev
                }
            });
        }
    }
}