export function countUp (query = document.querySelectorAll('.countUp')) {

    const animationDuration = 2000;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round(animationDuration / frameDuration);
    const easeOutQuad = t => t * (2 - t);

    const formatNumber = (num, useDot) => {
        return useDot ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : num;
    };

    const animateCountUp = el => {
        let frame = 0;
        const useDot = !el.classList.contains('noDot');
        const countTo = parseInt(el.innerHTML.replace(/\./g, ''), 10);
        const counter = setInterval(() => {
            frame++;
            const progress = easeOutQuad(frame / totalFrames);
            const currentCount = Math.round(countTo * progress);
            if (parseInt(el.innerHTML.replace(/\./g, ''), 10) !== currentCount) {
                el.innerHTML = formatNumber(currentCount, useDot);
            }
            if (frame === totalFrames) {
                clearInterval(counter);
            }
        }, frameDuration);
    };

    query.forEach( animateCountUp );
}