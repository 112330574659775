export function itemlistSlideshowAccount (query = document.querySelectorAll('.accountMain .itemboxList')) {
    for (let slideshow of query) {
        var slideshowSwiper = slideshow.swiper;
        if(slideshowSwiper){
            slideshowSwiper.params.slidesPerView = 3;

            if(window.screen.width < 1250 && window.screen.width > 540) {
                slideshowSwiper.params.slidesPerView = 2;
                slideshowSwiper.params.spaceBetween = 0;
            }

            if(window.screen.width <= 540) {
                slideshowSwiper.params.slidesPerView = 1.1;
                slideshowSwiper.params.spaceBetween = 0;
            }
        }
    }
}