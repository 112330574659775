import Swiper, {Autoplay, Pagination} from '../../node_modules/swiper';

export function manipulateSlideshow2 (query = document.querySelectorAll('.swiper-initialized.slideshow--2')) {
    for (let slideshow of query) {
        let slideshowSwiper = slideshow.swiper;
        slideshowSwiper.destroy(false, true);
        Swiper.use([Autoplay, Pagination]);
        let navElements = document.querySelectorAll('.productSwitchBanners');
        let skipFirst = true;
        let slideshowNew = new Swiper('.slideshow--2', {
           autoplay: false,
            effect: "fade",
            crossFade: true,
            slidesPerView: 1,
            pagination: {
                el: '.productSwitchNavigation',
                clickable: true,
                renderBullet: function (index, className) {
                    if(index < navElements.length) {
                        return '<span class="' + className + '">' + (navElements[index].dataset.title) + '</span>';
                    }
                    return '';
                },
            },
            breakpoints: {
                1250: {
                    direction: 'vertical',
                    slidesPerView: "auto",
                    autoHeight: true,
                    effect: "fade",
                }
            }
        });

        slideshowNew.on('slideChange', function () {
            let productBlock = document.querySelector('.productBlock');
           let slideTitle = slideshowNew.slides[slideshowNew.activeIndex].dataset.title;
           let newBg = "";
            switch (slideTitle) {
                case "Monatspaket":
                    newBg = "yellow-light";
                break;
                case "3-Monatspaket":
                    newBg = "primary-light";
                    break;
                case "6-Monatspaket":
                    newBg = "secondary-light";
                    break;
                case "12-Monatspaket":
                    newBg = "yellow-light";
                    break;
                case "24-Monatspaket":
                    newBg = "primary-light";
                    break;
                default:
                    newBg = "yellow-light";
            }

            removeClassWithPrefix(productBlock, "bg-");
            productBlock.classList.add('bg--' + newBg);

        });
        //slideshowSwiper.params.autoplay = false;
    }
}

function removeClassWithPrefix(element, prefix) {
    const classes = element.className.split(" ");
    const filteredClasses = classes.filter(c => !c.startsWith(prefix));
    element.className = filteredClasses.join(" ");
}