export function video (videoWrapper = document.querySelectorAll('.videoWrapper')) {
    if(videoWrapper) {
        for(let videoWrap of videoWrapper) {
            let playBtn = videoWrap.querySelector('.playBtn');
            let pauseBtn = videoWrap.querySelector('.pauseBtn');
            let video = videoWrap.querySelector('video');
            playBtn.addEventListener('click', () => {
                if(!playBtn.classList.contains('videoTriggered')) {
                    for(let i of video.querySelectorAll('source')) {
                        let src = i.src;
                        i.src = src.replace('_short', '_long');
                    }
                    video.load();
                    video.muted = !video.muted;
                    playBtn.classList.add('videoTriggered');
                }
                video.play();
                playBtn.style.display = 'none';
                pauseBtn.style.display = 'block';
                videoWrap.classList.add('videoActive');
            });
            pauseBtn.addEventListener('click', () => {
                video.pause();
                pauseBtn.style.display = 'none';
                playBtn.style.display = 'block';
                videoWrap.classList.remove('videoActive');
            })
        }
    }
}