export function shoppingSwitch (switcher = document.querySelector('.switchItemsWrapper')) {

    if(switcher) {
        for (const child of switcher.children) {
            child.addEventListener("click", function() {
                for (const childToggle of switcher.children) {
                    childToggle.classList.remove('activeSwitchItem');
                }
                let packagea = this.dataset.package;
                let images = document.querySelectorAll('.switchImageWrapper');
                for(let image of images) {
                    if(image.dataset.image != packagea) {
                        image.style.display = 'none';
                    } else {
                        image.style.display = 'block';
                    }
                }
                this.classList.add('activeSwitchItem');
            });
        }
    }

    let switchArrowRight = document.querySelector('.switchArrowRight');
    if(switchArrowRight) {
        switchArrowRight.addEventListener("click", function() {
            document.querySelector('.switchItem[data-package="countries"]').click();
        });
    }

    let switchArrowLeft = document.querySelector('.switchArrowLeft');
    if(switchArrowLeft) {
        switchArrowLeft.addEventListener("click", function() {
            document.querySelector('.switchItem[data-package="start"]').click();
        });
    }
}