export function login() {
    let form = document.querySelector('.childLogin');

    if(form) {
        let error = document.querySelector('.error-msg');
        let input = document.querySelector('input');
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            let inputValue = input.value.toUpperCase();
            let countries = {
                'ST72GZ29S' : 'startpaket',
                'JP74ZT32J' : 'japan',
                'AU48KG11A' : 'australien',
                'SA33WQ17S' : 'suedafrika',
                'BR69YL82B' : 'brasilien',
                'ME55IL35M' : 'mexiko',
                'GL22JB44G' : 'groenland',
                'FR26JZ17F' : 'frankreich',
                'US34FD38U' : 'usa',
                'ID83PL74I' : 'indien',
                'CH66LO41C' : 'china',
                'RU61IK85R' : 'russland',
                'IR92ZH28I' : 'israel',
                'EL24UZ77E' : 'england',
                'SN32HG22S' : 'spanien',
                'KA89UH17K' : 'kenia',
                'MS64KL18M' : 'mauritius',
                'RU92XY34R' : 'rumaenien',
                'KA26JL11K' : 'kanada',
                'AG33GH28A' : 'argentinien',
                'PH31BM24P' : 'philippinen',
                'SW77PD76S' : 'schweden',
                'AE39ZT76A' : 'aegypten',
                'TI55JK27T' : 'tibet'
            };

            let loginCorrect = false;
            for(let key in countries) {
                if(key === inputValue) {
                    loginCorrect = true;
                    window.location.href = '/de/kinderlogin/' + countries[key] + '/';
                }
            }

            if(!loginCorrect) {
                error.style.display = 'block';
            }
        });

        input.addEventListener('input', function () {
            error.style.display = 'none';
        })
    }
}