export function variantSelect (select = document.getElementById("itemcard_order_button_subsc_select")) {

    if(select) {
        fakeRadiobuttons();
        manipulateVariations();
    } else {
        let radioBoxWrapper = document.getElementById("selectBoxWrapper");
        if(radioBoxWrapper) {
            radioBoxWrapper.style.display = "none";
        }
    }

    let headline = document.querySelectorAll('.itemcardHeadline');
    for(let title of headline) {
        if(title.innerHTML == "Monatspaket" || title.innerHTML == "24-Monatspaket") {
            manipulateVariations();
        }
    }

    let buyContainer = document.querySelector('.itemcardBuybox__main');
    if(buyContainer) {
        buyContainer.classList.toggle('fade');
    }

}

function changeVariationText(element) {
    let oldText = element.innerHTML;
    if(oldText.includes("-Monatspaket")) {
        element.innerHTML = oldText.replace('-', ' ').replace('spaket', 'e');
    } else {
        //element.innerHTML = "Monatspaket";
    }
}


function manipulateVariations() {
    let vars = document.querySelectorAll('.variantSelection__item');
    for (let variant of vars) {
        changeVariationText(variant);
    }

    let timePeriod = document.querySelectorAll('.timePeriod span');
    for (let time of timePeriod) {
        changeVariationText(time);
    }
}

function getVariantPromotionText(id){
    const itemcardPromotions = document.getElementById("js-itemcardPromotions");

    if(itemcardPromotions){
        let promotion = itemcardPromotions.querySelector(".promotion--subscription[data-id='"+id+"']");
        if(promotion){
            return promotion.textContent;
        }
    }

    return false;
}

function fakeRadiobuttons() {
    let select = document.getElementById("itemcard_order_button_subsc_select");
    let selectedVal = select.value;
    let wrapper = document.getElementById("selectBoxWrapper");
    let options = document.querySelectorAll('#itemcard_order_button_subsc_select option');

    for (let option of options) {
        let price = option.dataset.priceformatted;
        let savings = option.dataset.savings;
        let optionLabel = option.innerHTML;
        let optionVal = option.value;

        let selectBox = document.createElement('div');
        selectBox.className = 'selectBox inputGroup';

        let label = document.createElement('label');
        label.className = "specialradiobox";
        label.textContent = optionLabel;


        let i = document.createElement('i');

        let radioButton = document.createElement('input');
        radioButton.type = 'radio';
        radioButton.value = optionVal;
        radioButton.name = 'paymentLula';

        if(optionVal == selectedVal) {
            radioButton.checked = true;
        }

        let promoText = getVariantPromotionText(option.value);

        let htmlBox = '<div class="variantPrice">' + price.replace("pro Monat", "<span>pro Monat</span>") + '</div>';
        htmlBox += '<div class="taxNote"><a class="js-openLinkInModal" href="/de/service/versandinfos/" title="Versanddetails">inkl. MwSt. zzgl. Versand</a></div>';
        if(promoText){
            htmlBox += '<div class="itemcardVariantPromotion">'+promoText+'</div>';
        }
        htmlBox += '</div>';

        label.appendChild(radioButton);
        label.appendChild(i);
        selectBox.appendChild(label);
        selectBox.insertAdjacentHTML("beforeend", htmlBox);

        wrapper.appendChild(selectBox);
        radioButton.addEventListener('click', function() {
            select.value = radioButton.value;
            select.dispatchEvent(new Event('change'));
        });

    }
}